<template>
  <div class="header__wrap">
    <header class="header">
      <div class="container">
        <div class="container__elem container__elem--12">
          <div class="header__content">
            <a class="header__logo" href="/">
              <img src="../assets/img/design/logo.svg" alt="">
            </a>
            <!-- /.logo -->
            <nav class="header__menu">
              <ul>
                <li><a href="/">Главная</a></li>
                <li><a href="#">Кейсы</a></li>
                <li><a href="/team">Команда</a></li>
                <li><a href="/contacts">Контакты</a></li>
                <li><a href="#">Консультации</a></li>
              </ul>
            </nav>
            <div class="header__social-list">
              <a class="header__social-item" href="https://api.whatsapp.com/send?phone=79015953988&amp;text=">
                <i>
                  <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M11.543 0C14.6048 0 17.4798 1.15289 19.6648 3.19384L19.6504 3.22996C26.811 10.1223 21.7386 22.0346 11.5487 22.0346C11.4413 22.0215 11.2948 22.0137 11.1136 22.0039C10.1713 21.9535 8.28933 21.8527 6.07097 20.6947L0 22.2245L1.62531 16.5183C-2.76284 9.20001 2.75517 0 11.543 0ZM17.3849 13.4142L17.3938 13.3431C17.4256 13.3568 17.4561 13.3699 17.4855 13.3824C17.7303 13.487 17.8918 13.556 17.957 13.6704C18.0312 13.7984 18.0312 14.3533 17.7792 15.022C17.5331 15.6908 16.3543 16.2884 15.7871 16.3747C15.2782 16.4449 14.6349 16.4752 13.9294 16.2599C11.3168 15.3986 9.88791 14.7783 7.2061 11.409C5.84148 9.56311 5.43931 7.82726 7.03416 6.14452C7.40076 5.77838 7.81677 5.77079 8.38593 5.81822C8.40389 5.81822 8.42244 5.81754 8.44151 5.81684C8.61662 5.81045 8.8361 5.80243 9.04996 6.30198C9.15828 6.5457 9.33251 6.95062 9.50028 7.34052C9.71515 7.83988 9.91941 8.31459 9.96103 8.39449C10.0341 8.53583 10.0845 8.69234 9.98573 8.89153C9.7533 9.31378 9.51121 9.57017 9.3306 9.76145C9.08275 10.0239 8.95067 10.1638 9.11814 10.4415C9.29206 10.726 9.88099 11.6509 10.7585 12.405C11.7013 13.2148 12.5216 13.5561 12.9378 13.7293C13.0189 13.763 13.0846 13.7904 13.133 13.8136C13.4264 13.9416 13.5984 13.9274 13.7713 13.7424C14.1078 13.3725 14.3326 13.0945 14.4968 12.8913C14.9004 12.3921 14.9386 12.3449 15.3691 12.4979C15.6389 12.5918 17.0875 13.272 17.3849 13.4142Z"
                      fill="white" />
                  </svg>
                </i>


              </a>
              <a class="header__social-item" href="https://vk.com/remixmeplease">
                <i>
                  <svg width="28" height="16" viewBox="0 0 28 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M23.235 9.17651C22.7823 8.66921 22.9118 8.44352 23.235 7.99006C23.2408 7.98488 26.9778 3.40264 27.3628 1.84865L27.3651 1.84761C27.5565 1.2813 27.3651 0.865112 26.4399 0.865112H23.3785C22.5991 0.865112 22.2398 1.22229 22.0473 1.62192C22.0473 1.62192 20.4885 4.93281 18.2835 7.07899C17.5718 7.69914 17.2428 7.89792 16.8542 7.89792C16.6629 7.89792 16.3654 7.69914 16.3654 7.13283V1.84761C16.3654 1.16846 16.1472 0.865112 15.502 0.865112H10.6882C10.1994 0.865112 9.90884 1.18191 9.90884 1.47698C9.90884 2.12093 11.0114 2.26898 11.1257 4.08076V8.0118C11.1257 8.87317 10.9495 9.03157 10.5587 9.03157C9.518 9.03157 6.99208 5.70722 5.49519 1.90248C5.19301 1.16431 4.89784 0.866148 4.11264 0.866148H1.05004C0.176173 0.866148 0 1.22333 0 1.62295C0 2.32903 1.0407 5.83974 4.8395 10.4779C7.37125 13.6438 10.9367 15.3593 14.1802 15.3593C16.1297 15.3593 16.3677 14.9783 16.3677 14.323C16.3677 11.2978 16.1916 11.0121 17.1681 11.0121C17.6208 11.0121 18.4001 11.2109 20.2202 12.7379C22.3004 14.5487 22.6423 15.3593 23.8067 15.3593H26.8681C27.7408 15.3593 28.183 14.9783 27.9286 14.2267C27.3464 12.6458 23.4123 9.39393 23.235 9.17651Z"
                      fill="white" />
                  </svg>
                </i>

              </a>
              <a class="header__social-item" href="https://t.me/remixmeplease">
                <i>
                  <svg width="23" height="20" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M9.02501 12.9989L8.64455 18.1248C9.18889 18.1248 9.42465 17.9008 9.70736 17.6318L12.2595 15.2956L17.5477 19.0051C18.5175 19.5228 19.2008 19.2502 19.4625 18.1505L22.9336 2.57082L22.9346 2.5699C23.2422 1.19663 22.4161 0.659618 21.4712 0.996511L1.06778 8.47882C-0.324712 8.99655 -0.303629 9.7401 0.831065 10.077L6.0474 11.6311L18.1639 4.36911C18.7341 4.00743 19.2526 4.20754 18.8261 4.56922L9.02501 12.9989Z"
                      fill="white" />
                  </svg>
                </i>

              </a>
            </div>
            <a class="header__number" href="tel:88001016856">
              8 (800) 101 68 56
            </a>
            <div class="header__burger">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
export default {
  name: 'Headers',
  mounted() {
    let burger = document.querySelector('.header__burger');
    let menu = document.querySelector('.header__menu');
    let html = document.querySelector('html');

    burger.addEventListener('click', () => {
      menu.classList.toggle('active');
      burger.classList.toggle('active');
      html.classList.toggle('scroll-none');
    })

    
    // Ждем, пока весь контент загрузится
    window.addEventListener("load", function () {
      // Скрываем прелоадер после загрузки контента
      document.querySelector("#app").style.display = "block";
      setTimeout(() => {
        document.querySelector(".preloader").classList.add('hide');
      }, 200);
      setTimeout(() => {
        document.querySelector(".preloader").style.display = "none";
      }, 600);
    });

  }

}
</script>