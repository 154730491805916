<template>
  <section class="one">
    <video class="one__video" src="../assets/video/one__video.mp4" muted autoplay loop></video>
    <div class="container">
      <div class="container__elem container__elem--12">
        <div class="one__inner">
          <div class="carousel-container">
            <div class="carousel-track">
              <div class="carousel-item">
                <img src="../assets/img/one-partner-1.png" alt="img">
              </div>
              <div class="carousel-item">
                <img src="../assets/img/one-partner-2.png" alt="img">
              </div>
              <div class="carousel-item">
                <img src="../assets/img/one-partner-3.png" alt="img">
              </div>
              <div class="carousel-item">
                <img src="../assets/img/one-partner-4.png" alt="img">
              </div>
              <div class="carousel-item">
                <img src="../assets/img/one-partner-5.png" alt="img">
              </div>
              <div class="carousel-item">
                <img src="../assets/img/one-partner-6.png" alt="img">
              </div>
              <div class="carousel-item">
                <img src="../assets/img/one-partner-7.png" alt="img">
              </div>
              <div class="carousel-item">
                <img src="../assets/img/one-partner-8.png" alt="img">
              </div>
              <div class="carousel-item">
                <img src="../assets/img/one-partner-9.png" alt="img">
              </div>
              <div class="carousel-item">
                <img src="../assets/img/one-partner-10.png" alt="img">
              </div>
              <div class="carousel-item">
                <img src="../assets/img/one-partner-11.png" alt="img">
              </div>
              <div class="carousel-item">
                <img src="../assets/img/one-partner-12.png" alt="img">
              </div>
              <div class="carousel-item">
                <img src="../assets/img/one-partner-13.png" alt="img">
              </div>
              <div class="carousel-item">
                <img src="../assets/img/one-partner-14.png" alt="img">
              </div>
              <div class="carousel-item">
                <img src="../assets/img/one-partner-15.png" alt="img">
              </div>
            </div>
            <div class="carousel-track">
              <div class="carousel-item">
                <img src="../assets/img/one-partner-1.png" alt="img">
              </div>
              <div class="carousel-item">
                <img src="../assets/img/one-partner-2.png" alt="img">
              </div>
              <div class="carousel-item">
                <img src="../assets/img/one-partner-3.png" alt="img">
              </div>
              <div class="carousel-item">
                <img src="../assets/img/one-partner-4.png" alt="img">
              </div>
              <div class="carousel-item">
                <img src="../assets/img/one-partner-5.png" alt="img">
              </div>
              <div class="carousel-item">
                <img src="../assets/img/one-partner-6.png" alt="img">
              </div>
              <div class="carousel-item">
                <img src="../assets/img/one-partner-7.png" alt="img">
              </div>
              <div class="carousel-item">
                <img src="../assets/img/one-partner-8.png" alt="img">
              </div>
              <div class="carousel-item">
                <img src="../assets/img/one-partner-9.png" alt="img">
              </div>
              <div class="carousel-item">
                <img src="../assets/img/one-partner-10.png" alt="img">
              </div>
              <div class="carousel-item">
                <img src="../assets/img/one-partner-11.png" alt="img">
              </div>
              <div class="carousel-item">
                <img src="../assets/img/one-partner-12.png" alt="img">
              </div>
              <div class="carousel-item">
                <img src="../assets/img/one-partner-13.png" alt="img">
              </div>
              <div class="carousel-item">
                <img src="../assets/img/one-partner-14.png" alt="img">
              </div>
              <div class="carousel-item">
                <img src="../assets/img/one-partner-15.png" alt="img">
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </section>
  <section class="two">
    <div class="container">
      <div class="container__elem container__elem--12">
        <div class="two__inner">
          <h2 class="two__title">
            НАШИ УСЛУГИ
          </h2>
          <div class="two__items">
            <div class="two__item">
              <div class="two__item-wrap">
                <img class="two__item-img" src="../assets/img/two-item-img-1.png" alt="img">
                <p class="two__item-text">WEB-студия</p>
              </div>
            </div>
            <div class="two__item">
              <div class="two__item-wrap">
                <img class="two__item-img" src="../assets/img/two-item-img-2.png" alt="img">
                <p class="two__item-text">SMM</p>
              </div>
            </div>
            <div class="two__item">
              <div class="two__item-wrap">
                <img class="two__item-img" src="../assets/img/two-item-img-3.png" alt="img">
                <p class="two__item-text">Брендинг <br>
                  и дизайн</p>
              </div>
            </div>
            <div class="two__item">
              <div class="two__item-wrap">
                <img class="two__item-img" src="../assets/img/two-item-img-4.png" alt="img">
                <p class="two__item-text">Стратегия <br>
                  и Консультации</p>
              </div>
            </div>
            <div class="two__item">
              <div class="two__item-wrap">
                <img class="two__item-img" src="../assets/img/two-item-img-5.png" alt="img">
                <p class="two__item-text">Спецпроекты <br>
                  и PR</p>
              </div>
            </div>
            <div class="two__item">
              <div class="two__item-wrap">
                <img class="two__item-img" src="../assets/img/two-item-img-6.png" alt="img">
                <p class="two__item-text">Продвижение <br>
                  в Telegram</p>
              </div>
            </div>
            <div class="two__item">
              <div class="two__item-wrap">
                <img class="two__item-img" src="../assets/img/two-item-img-7.png" alt="img">
                <p class="two__item-text">Цифровые <br>
                  воронки</p>
              </div>
            </div>
            <div class="two__item">
              <div class="two__item-wrap">
                <img class="two__item-img" src="../assets/img/two-item-img-8.png" alt="img">
                <p class="two__item-text">Контент <br>
                  и продакшн</p>
              </div>
            </div>
          </div>
          <div class="two__bottom" id="two__bottom">
            <p class="two__bottom-text">
              ОСТАВЬТЕ ЗАЯВКУ, ЧТОБЫ ОБСУДИТЬ ВАШ ПРОЕКТ
            </p>
            <form class="two__bottom-form" v-on:submit.prevent="onSubmit">
              <input name="client-name" class="two__form-input" type="text" placeholder="Имя" required>
              <input name="client-phone" id="client-phone" class="two__form-input" type="text" placeholder="+7 (999) 999-99-99" required>
              <button class="btn" type="submit">
                <p>СВЯЖИТЕСЬ со мной</p>
              </button>
              <a class="two__bottom-social" href="https://api.whatsapp.com/send?phone=79015953988&amp;text=">
                <i>
                  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M0.711907 35.2976L3.02604 26.8897L3.08165 26.6876L2.9766 26.5063C-3.69687 14.9883 4.69407 0.5 18.0673 0.5C22.6164 0.5 26.8951 2.22982 30.193 5.30592L30.1735 5.35643L30.4042 5.58624C41.2877 16.4276 33.5916 35.1683 18.1063 35.1925C17.924 35.1712 17.6894 35.1579 17.4077 35.1419C15.9149 35.0571 13.1 34.8972 9.74003 33.0821L9.56677 32.9885L9.37621 33.0382L0.711907 35.2976Z"
                      stroke="white" />
                    <path
                      d="M27.225 21.6136L27.2111 21.7288C26.7456 21.4983 24.4782 20.3966 24.0559 20.2445C23.1078 19.8957 23.3754 20.1892 21.555 22.2604C21.2843 22.56 21.0152 22.5831 20.5559 22.3757C20.0919 22.1452 18.6024 21.6612 16.8393 20.0939C15.4658 18.8724 14.544 17.3743 14.2718 16.9134C13.8186 16.1359 14.7667 16.0253 15.6298 14.4027C15.7844 14.0801 15.7056 13.8266 15.5911 13.5976C15.4751 13.3671 14.5518 11.1085 14.1651 10.2081C13.7939 9.31077 13.4119 9.42447 13.1257 9.42447C12.2349 9.34765 11.5837 9.35994 11.0099 9.95303C8.51363 12.6788 9.14311 15.4906 11.279 18.4806C15.4767 23.9383 17.7131 24.9432 21.8025 26.3383C22.9068 26.6871 23.9136 26.6379 24.7102 26.5242C25.5979 26.3844 27.4431 25.4164 27.8282 24.3332C28.2226 23.2499 28.2226 22.3511 28.1066 22.1436C27.9922 21.9362 27.689 21.821 27.225 21.6136Z"
                      fill="white" />
                  </svg>

                </i>
              </a>
            </form>
            <div class="two__bottom-conf">
              <p>* Нажимая на кнопку вы соглашаетесь с </p>
              <a href="/conf">Политикой конфиденциальности и обработки персональных данных</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="three">
    <div class="three__inner">
      <img src="../assets/img/three-video.png" alt="">
      <button class="three__play" @click="openPopup">
        <i>
          <svg width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="70" cy="70" r="70" fill="#622DE1" />
            <path
              d="M87.9312 68.5308C90.5978 70.0704 90.5978 73.9194 87.9312 75.459L64.9311 88.738C62.2645 90.2776 58.9311 88.3531 58.9311 85.2739V58.7158C58.9311 55.6366 62.2645 53.7121 64.9311 55.2517L87.9312 68.5308Z"
              fill="white" />
          </svg>

        </i>
      </button>
      <PopupComponent ref="popup"></PopupComponent>

    </div>
  </section>
  <section class="four">
    <div class="container">
      <div class="container__elem container__elem--12">
        <div class="four__inner">
          <h2 class="four__title">
            ключевые направления
          </h2>
          <div class="four__content">
            <div class="four__box">
              <div class="four__box-item">
                <p>FMCG</p>
              </div>
              <div class="four__box-item">
                <p>Ритейл</p>
              </div>
              <div class="four__box-item">
                <p>финтех</p>
              </div>
              <div class="four__box-item">
                <p>медицина</p>
              </div>
              <video class="four__box-video" src="../assets/video/sc_1.mp4" muted autoplay loop></video>
            </div>
            <div class="four__info">
              <p class="four__text">Мы всегда готовы реализовать нестандартные и интересные задачи <br>
                и в других отраслях</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="five">
    <div class="five__video-bg">
      <div class="five__video-wrap">
        <video src="../assets/video/sc_3.mp4" muted autoplay loop></video>
      </div>
    </div>
    <div class="container">
      <div class="container__elem container__elem--12">
        <div class="five__inner">
          <h2 class="five__title">
            КЕЙСЫ
          </h2>
          <div class="five__info">
            <p class="five__info-text">Каждый из фаундеров получил опыт внедрения стратегии маркетинга и проведения
              цифровой трансформации в банках, крупных корпорациях и ритейл-сетях.
              <br><br>
              Мы встретились, чтобы соединить это для пользы бизнеса наших клиентов.
            </p>
          </div>
          <div class="five__items">
            <div class="five__item">
              <div class="five__item-wrap">
              </div>
              <img src="../assets/img/five-item-1.png" alt="" class="five__item-img">
              <div class="five__item-info">
                <p class="five__item-text">Продвижение в Telegram</p>
              </div>
            </div>
            <div class="five__item">
              <div class="five__item-wrap">
              </div>
              <img src="../assets/img/five-item-2.png" alt="" class="five__item-img">
              <div class="five__item-info">
                <p class="five__item-text">SMM-сопровождение</p>
                <p class="five__item-text">Продвижение в Telegram</p>
              </div>
            </div>
            <div class="five__item">
              <div class="five__item-wrap">
              </div>
              <img src="../assets/img/five-item-3.png" alt="" class="five__item-img">
              <div class="five__item-info">
                <p class="five__item-text">Брендинг и дизайн</p>
                <p class="five__item-text">SMM-сопровождение</p>
              </div>
            </div>
          </div>
          <div class="five__item-btn">
            <a class="btn" href="/">
              <p>Посмотреть еще</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="six">
    <div class="container">
      <div class="container__elem container__elem--12">
        <div class="six__inner">
          <h2 class="six__title six__title--one">
            ЭТО РЕМИКС ЗНАНИЙ,
          </h2>
          <h2 class="six__title six__title--two">
            ПОДХОДОВ И ВЗГЛЯДОВ
          </h2>
          <div class="six__content">
            <div class="six__item">
              <div class="six__item-wrap">
                <h3 class="six__item-title">Наши <br> достижения</h3>
                <p class="six__item-text">Наш подход нацелен на результат, который <br> измеряется в полученной прибыли.
                </p>
              </div>
              <video src="../assets/video/sc_1.mp4" muted autoplay loop></video>
            </div>
            <div class="six__item">
              <div class="six__item-wrap">
                <h3 class="six__item-title">БОЛЕЕ 3-Х ЛЕТ </h3>
                <p class="six__item-text">присутствия агентства на рынке</p>
              </div>
              <video src="../assets/video/sc_2.mp4" muted autoplay loop></video>
            </div>
            <div class="six__item">
              <div class="six__item-wrap">
                <h3 class="six__item-title">5000+</h3>
                <p class="six__item-text">дизайнерских креативов</p>
              </div>
            </div>
            <div class="six__item">
              <div class="six__item-wrap">
                <h3 class="six__item-title">12+ лет</h3>
                <p class="six__item-text">опыта основателей в финансовой <br> сфере, бизнесе и маркетинге</p>
              </div>
              <video src="../assets/video/sc_3.mp4" muted autoplay loop></video>
            </div>
            <div class="six__item">
              <div class="six__item-wrap">
                <h3 class="six__item-title">40+</h3>
                <p class="six__item-text">созданных стратегий</p>
              </div>
            </div>
            <div class="six__item">
              <div class="six__item-wrap">
                <h3 class="six__item-title">10+ млн</h3>
                <p class="six__item-text">целевая аудитория проектов</p>
              </div>
              <video src="../assets/video/sc_4.mp4" muted autoplay loop></video>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="seven">
    <div class="container">
      <div class="container__elem container__elem--12">
        <div class="seven__inner">
          <div class="seven__graph">
            <a class="seven__graph-item" href="#">
              <h5 class="seven__graph-title">Презентация</h5>
              <h6 class="seven__graph-text">о компании</h6>
            </a>
            <a class="seven__graph-item" href="https://t.me/remixmeplease">
              <h5 class="seven__graph-title">Telegram</h5>
              <h6 class="seven__graph-text">канал о маркетинге</h6>
            </a>
            <a class="seven__graph-item" href="https://vk.com/remixmeplease">
              <h5 class="seven__graph-title">ВКонтакте</h5>
              <h6 class="seven__graph-text">официальное сообщество</h6>
            </a>
            <a class="seven__graph-item" href="https://vc.ru/u/1113730-aleksandra-mironova">
              <h5 class="seven__graph-title">VC.ru</h5>
              <h6 class="seven__graph-text">блог основателей</h6>
            </a>
            <a class="seven__graph-item" href="https://www.behance.net/ef343378">
              <h5 class="seven__graph-title">Behance</h5>
              <h6 class="seven__graph-text">дизайн кейсы</h6>
            </a>
          </div>
          <div class="seven__info">
            <h2 class="seven__info-title">Наши основатели – кофаундеры ряда технологичных проектов, которые выделят вас
              среди тысячи других</h2>
            <div class="seven__info-btns">
              <a class="btn" href="https://t.me/nft999">
                <p>написать основателю</p>
              </a>
              <a class="btn" href="/team">
                <p>КОМАНДА</p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="eight">
    <div class="container">
      <div class="container__elem container__elem--12">
        <div class="eight__inner">
          <div class="eight__info">
            <h2 class="eight__info-title">Напишите или <br> позвоните нам</h2>
            <div class="eight__info-links">
              <a href="tel:88001016856">
                <i>
                  <svg width="40" height="35" viewBox="0 0 40 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M37.9794 24.8459L37.9497 25.0801C36.9583 24.6116 32.1296 22.3721 31.2304 22.0629C29.2113 21.3539 29.7811 21.9504 25.9043 26.1608C25.3279 26.7699 24.7548 26.8168 23.7765 26.3951C22.7884 25.9266 19.6164 24.9427 15.8615 21.7568C12.9366 19.2736 10.9735 16.2283 10.3938 15.2912C9.42874 13.7108 11.4478 13.4859 13.2858 10.1875C13.6151 9.53161 13.4472 9.01624 13.2034 8.55084C12.9564 8.08233 10.99 3.49086 10.1665 1.66052C9.37603 -0.163574 8.56247 0.0675615 7.95311 0.0675615C6.05589 -0.0886108 4.6692 -0.0636237 3.4472 1.14203C-1.86898 6.68302 -0.528407 12.3989 4.02032 18.4772C12.9597 29.5716 17.7225 31.6144 26.4313 34.4505C28.7831 35.1595 30.9273 35.0595 32.6236 34.8284C34.5143 34.5442 38.4438 32.5764 39.2639 30.3744C40.1038 28.1723 40.1038 26.3451 39.8568 25.9235C39.6131 25.5018 38.9675 25.2675 37.9794 24.8459Z"
                      fill="white" />
                  </svg>

                </i>
                <p>8 800 101 68 56</p>
              </a>
              <a href="tel:89015953988">
                <i>
                  <svg width="40" height="35" viewBox="0 0 40 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M37.9794 24.8459L37.9497 25.0801C36.9583 24.6116 32.1296 22.3721 31.2304 22.0629C29.2113 21.3539 29.7811 21.9504 25.9043 26.1608C25.3279 26.7699 24.7548 26.8168 23.7765 26.3951C22.7884 25.9266 19.6164 24.9427 15.8615 21.7568C12.9366 19.2736 10.9735 16.2283 10.3938 15.2912C9.42874 13.7108 11.4478 13.4859 13.2858 10.1875C13.6151 9.53161 13.4472 9.01624 13.2034 8.55084C12.9564 8.08233 10.99 3.49086 10.1665 1.66052C9.37603 -0.163574 8.56247 0.0675615 7.95311 0.0675615C6.05589 -0.0886108 4.6692 -0.0636237 3.4472 1.14203C-1.86898 6.68302 -0.528407 12.3989 4.02032 18.4772C12.9597 29.5716 17.7225 31.6144 26.4313 34.4505C28.7831 35.1595 30.9273 35.0595 32.6236 34.8284C34.5143 34.5442 38.4438 32.5764 39.2639 30.3744C40.1038 28.1723 40.1038 26.3451 39.8568 25.9235C39.6131 25.5018 38.9675 25.2675 37.9794 24.8459Z"
                      fill="white" />
                  </svg>

                </i>
                <p>8 901 595 3988</p>
              </a>
              <a href="mailto:AM@remixmedia.ru">
                <i>
                  <svg width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M3.51733 13.049V37.9722L15.9789 25.5106L3.51733 13.049ZM5.99347 10.5518L21.7701 26.3283C23.7626 28.3209 27.2376 28.3209 29.2301 26.3283L45.0067 10.5518H5.99347Z"
                      fill="white" />
                    <path
                      d="M31.7166 28.8149C30.0583 30.475 27.8494 31.3912 25.4999 31.3912C23.1504 31.3912 20.9416 30.475 19.2832 28.8149L18.4654 27.9971L6.0144 40.4481H44.9854L32.5344 27.9971L31.7166 28.8149ZM35.0211 25.5104L47.4827 37.972V13.0488L35.0211 25.5104Z"
                      fill="white" />
                  </svg>

                </i>
                <p>AM@remixmedia.ru</p>
              </a>
            </div>
          </div>
          <form class="eight__form">
            <input name="client-name" class="eight__form-input" type="text" placeholder="Имя" required>
            <input  name="client-phone2" id="client-phone2" class="eight__form-input" type="text" placeholder="Номер телефона" required>
            <div class="eight__textaera">
              <textarea class="eight__form-input" rows="3" cols="30"
                placeholder="Приложите ссылку или прикрепите бриф по вашей задаче" />
              <div class="input-container">
                <input type="file" id="fileInput" />
                <label for="fileInput">
                  <div class="icon-container">
                    <i>
                      <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M10.498 13.5981L6.94592 17.1502L7.85009 18.0544L11.4022 14.5023L11.0793 13.9211L10.498 13.5981Z"
                          fill="white" />
                        <path
                          d="M14.5021 11.4023L18.1833 7.72106L17.2791 6.81689L13.5979 10.4981L13.9208 11.0794L14.5021 11.4023Z"
                          fill="white" />
                        <path d="M9.91675 7.97949L7.97925 9.91699L12.5001 12.5003L9.91675 7.97949Z" fill="white" />
                        <path d="M12.5 12.5L15.0833 17.0208L17.0208 15.0833L12.5 12.5Z" fill="white" />
                        <path
                          d="M11.5313 21.6709C10.4216 22.6426 8.98398 23.156 7.50983 23.1071C6.03568 23.0583 4.63523 22.4508 3.59228 21.4079C2.54933 20.3649 1.94188 18.9645 1.89303 17.4903C1.84418 16.0162 2.35758 14.5786 3.3292 13.4688L5.13754 11.6605L4.23337 10.7563L2.42504 12.5647C1.42578 13.5583 0.74461 14.8267 0.468143 16.2085C0.191676 17.5903 0.332404 19.0231 0.872433 20.3247C1.41246 21.6263 2.32739 22.7379 3.50087 23.5181C4.67435 24.2984 6.05336 24.712 7.46254 24.7063C9.34517 24.6918 11.1496 23.9515 12.5 22.6397L14.3084 20.8313L13.4042 19.9272L11.5313 21.6709Z"
                          fill="white" />
                        <path
                          d="M22.7043 2.29607C22.0441 1.63215 21.2591 1.10529 20.3946 0.74577C19.5301 0.386252 18.603 0.201172 17.6668 0.201172C16.7305 0.201172 15.8034 0.386252 14.9389 0.74577C14.0744 1.10529 13.2895 1.63215 12.6293 2.29607L10.8209 4.1044L11.7251 5.00857L13.5334 3.20024C14.0576 2.60156 14.6991 2.11689 15.4182 1.77624C16.1374 1.43559 16.9188 1.24623 17.7141 1.21987C18.5093 1.19352 19.3016 1.33074 20.0417 1.62303C20.7818 1.91532 21.454 2.35646 22.0166 2.91912C22.5793 3.48179 23.0204 4.15398 23.3127 4.89408C23.605 5.63417 23.7422 6.4264 23.7159 7.22169C23.6895 8.01698 23.5002 8.79838 23.1595 9.5175C22.8189 10.2366 22.3342 10.8781 21.7355 11.4023L19.9272 13.2107L20.8313 14.1148L22.6397 12.3065C23.3106 11.6588 23.8453 10.8836 24.2125 10.0265C24.5797 9.16927 24.772 8.24737 24.778 7.31487C24.784 6.38236 24.6037 5.45806 24.2476 4.59621C23.8915 3.73436 23.3668 2.95234 22.7043 2.29607V2.29607Z"
                          fill="white" />
                      </svg>
                    </i>
                  </div>
                </label>
              </div>
            </div>
            <div class="eight__form-btn--wrap">
              <button class="eight__form-btn btn btn--large">
                <p>Свяжитесь со мной</p>
              </button>
            </div>
            <div class="eight__bottom-conf">
              <p>Нажимая на кнопку вы соглашаетесь с </p>
              <a href="/conf"> Политикой конфиденциальности <br> и обработки персональных данных</a>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>


import PopupComponent from '../components/Popup.vue'; // Импортируйте компонент

export default {
  name: 'HomeView',
  components: {
    PopupComponent // Зарегистрируйте компонент для использования
  },
  data() {
    return {
      phoneNumber: '',
      inputNumber: '',
    };
  },
  methods: {
    openPopup() {
      this.$refs.popup.openPopup(); // Вызов метода openPopup из компонента Popup
    },
  },
  mounted() {
    // Подключение к текстовому полю
    document.querySelector('#client-phone').onkeydown = function (e) {
      inputPhone(e, document.querySelector('#client-phone'))
    }
    document.querySelector('#client-phone2').onkeydown = function (e) {
      inputPhone(e, document.querySelector('#client-phone2'))
    }

    function inputPhone(e, phone) {
      function stop(evt) {
        evt.preventDefault();
      }

      let key = e.key, value = phone.value, not = key.replace(/([0-9])/, 1);

      if (not == 1 || 'Backspace' === not) {
        if ('Backspace' != not) {
          if (value.length < 4 || value === '') {
            phone.value = '+7 (';
          }
          if (value.length === 7) {
            phone.value = value + ') ';
          }
          if (value.length === 12) {
            phone.value = value + '-';
          }
          if (value.length === 15) {
            phone.value = value + '-';
          }
        }
      } else {
        stop(e);
      }
      if (value.length > 17) {
      phone.value = value.substring(0, 17);
      }
    }
    function isElementNearBottom(el, offset) {
      const rect = el.getBoundingClientRect();
      const windowHeight = window.innerHeight || document.documentElement.clientHeight;
      const distanceFromBottom = windowHeight - rect.bottom;
      return distanceFromBottom <= offset;

    }


    function addRemoveAnimForSmallScreen() {
      const twoBottom = document.querySelector('.two__bottom');
      const threeInner = document.querySelector('.three__inner');

      if (window.innerWidth < 1024) {
        document.addEventListener('scroll', function () {
          if (!isElementNearBottom(twoBottom, -600)) {
            twoBottom.classList.add('anim');
          } else {
            twoBottom.classList.remove('anim');
          }
        });

        document.addEventListener('scroll', function () {
          if (!isElementNearBottom(threeInner, -300)) {
            threeInner.classList.add('anim');
          } else {
            threeInner.classList.remove('anim');
          }
        });
      } else {
        document.addEventListener('scroll', function () {
          if (!isElementNearBottom(twoBottom, -200)) {
            twoBottom.classList.add('anim');
          } else {
            twoBottom.classList.remove('anim');
          }
        });

        document.addEventListener('scroll', function () {
          if (!isElementNearBottom(threeInner, -900)) {
            threeInner.classList.add('anim');
          } else {
            threeInner.classList.remove('anim');
          }
        });
      }
    }

    addRemoveAnimForSmallScreen();




    const fiveItems = document.querySelector('.five__items');
    const fiveItem = document.querySelectorAll('.five__item');

    function isElementInViewport(el) {
      const rect = el.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
      );
    }

    function isElementNearBottom(el, offset) {
      const rect = el.getBoundingClientRect();
      const windowHeight = window.innerHeight || document.documentElement.clientHeight;
      const distanceFromBottom = windowHeight - rect.bottom;
      return distanceFromBottom <= offset;
    }

    function addAnimationOnScroll() {
      function onScroll() {
        if (isElementInViewport(fiveItems)) {
          if (isElementNearBottom(fiveItems, 200)) {
            fiveItem.forEach(item => {
              item.classList.add('anim');
            });
          }
        }
      }

      document.addEventListener('scroll', onScroll);
    }

    addAnimationOnScroll();


    const fiveBtn = document.querySelector('.five__item-btn');
    function isElementInViewport(el) {
      const rect = el.getBoundingClientRect();
      return (
        rect.bottom >= 0 &&
        rect.right >= 0 &&
        rect.top <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.left <= (window.innerWidth || document.documentElement.clientWidth)
      );
    }

    function onScroll() {
      if (isElementInViewport(fiveBtn)) {
        const viewportHeight = window.innerHeight || document.documentElement.clientHeight;
        const distanceFromBottom = viewportHeight - fiveBtn.getBoundingClientRect().bottom;
        if (distanceFromBottom <= 100) {
          fiveBtn.classList.add('anim');
        }
      }
    }
    document.addEventListener('scroll', onScroll);


    const sixTitles = document.querySelectorAll('.six__title');

    function isElementNearBottom(el, offset) {
      const rect = el.getBoundingClientRect();
      const windowHeight = window.innerHeight || document.documentElement.clientHeight;
      const distanceFromBottom = windowHeight - rect.bottom;
      return distanceFromBottom <= offset;
    }

    function animateOnScroll() {
      sixTitles.forEach(item => {
        function onScroll() {
          if (isElementNearBottom(item, 100)) {
            item.classList.remove('anim');
          } else {
            item.classList.add('anim');
          }
        }

        document.addEventListener('scroll', onScroll);
      });
    }

    animateOnScroll();



  }
};
</script>