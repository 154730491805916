<template>
  <div id="page">
    <Headers />
    <main>
      <router-view />
    </main>
    <Footers />
  </div>
</template>

<style lang="less">
@import "../src/styles/main.less";
</style>

<script>
// @ is an alias to /src
import Headers from '@/components/Headers.vue'
import Footers from '@/components/Footers.vue'

export default {
  name: 'App',
  components: {
    Headers,
    Footers
  }
}
</script>
