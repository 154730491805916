<template>
  <footer class="footer">
    <div class="container">
      <div class="container__elem container__elem--12">
        <div class="footer__inner">
          <div class="footer__logo">
            <a href="/">
              <img src="../assets/img/design/logo.svg" alt="logo">
            </a>
          </div>
          <div class="footer__menu">
            <div class="footer__menu-item">
              <h6 class="footer__menu-title">Наши телеграм-каналы</h6>
              <a href="https://t.me/remixmeplease" class="footer__menu-link">
                Игра в бисер
              </a>
              <a href="#" class="footer__menu-link">
                Спирит эстетик
              </a>
              <a href="#" class="footer__menu-link">
                ПП рецепты
              </a>
              <a href="#" class="footer__menu-link">
                КаШа
              </a>
            </div>
            <div class="footer__menu-item">
              <h6 class="footer__menu-title">Кейсы</h6>
              <a href="#" class="footer__menu-link">
                FMCG
              </a>
              <a href="#" class="footer__menu-link">
                Ритейл
              </a>
              <a href="#" class="footer__menu-link">
                Медицина
              </a>
              <a href="#" class="footer__menu-link">
                Финтех
              </a>
              <a href="#" class="footer__menu-link">
                Спецпроекты
              </a>
            </div>
            <div class="footer__menu-item">
              <h6 class="footer__menu-title">Команда</h6>
              <a href="#" class="footer__menu-link">
                Вакансии
              </a>
              <a href="#" class="footer__menu-link">
                Люди
              </a>
            </div>
            <div class="footer__menu-item">
              <h6 class="footer__menu-title">Контакты</h6>
              <a href="https://vk.com/remixmeplease" class="footer__menu-link footer__menu-link--social">
                <i>
                  <svg width="22" height="14" viewBox="0 0 22 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M18.2554 7.9425C17.8998 7.49333 18.0015 7.2935 18.2554 6.892C18.26 6.88741 21.1961 2.83025 21.4986 1.45433L21.5004 1.45341C21.6508 0.951996 21.5004 0.583496 20.7735 0.583496H18.3682C17.7558 0.583496 17.4735 0.899746 17.3223 1.25358C17.3223 1.25358 16.0976 4.18508 14.3651 6.08533C13.8059 6.63441 13.5474 6.81041 13.2422 6.81041C13.0918 6.81041 12.8581 6.63441 12.8581 6.133V1.45341C12.8581 0.852079 12.6867 0.583496 12.1798 0.583496H8.39758C8.0135 0.583496 7.78525 0.863996 7.78525 1.12525C7.78525 1.69541 8.6515 1.8265 8.74133 3.43066V6.91125C8.74133 7.67391 8.60292 7.81416 8.29583 7.81416C7.47817 7.81416 5.49358 4.87075 4.3175 1.502C4.08008 0.848413 3.84817 0.584413 3.23125 0.584413H0.825C0.138417 0.584413 0 0.900663 0 1.2545C0 1.87966 0.817666 4.98808 3.80233 9.09475C5.7915 11.8979 8.59283 13.4168 11.1412 13.4168C12.6729 13.4168 12.8599 13.0795 12.8599 12.4992C12.8599 9.82075 12.7215 9.56775 13.4888 9.56775C13.8444 9.56775 14.4568 9.74375 15.8868 11.0958C17.5212 12.6991 17.7898 13.4168 18.7046 13.4168H21.1099C21.7956 13.4168 22.143 13.0795 21.9432 12.414C21.4858 11.0142 18.3948 8.135 18.2554 7.9425Z"
                      fill="white" />
                  </svg>

                </i>
                <p>
                  Наша группа ВКонтакте
                </p>
              </a>
              <a href="https://t.me/remixmeplease" class="footer__menu-link footer__menu-link--social">
                <i>
                  <svg width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M7.45514 11.0183L7.14085 15.439C7.59052 15.439 7.78527 15.2458 8.01881 15.0138L10.127 12.999L14.4954 16.1982C15.2966 16.6447 15.8611 16.4095 16.0772 15.4611L18.9446 2.02495L18.9454 2.02416C19.1995 0.839829 18.5171 0.376704 17.7365 0.667246L0.881935 7.12012C-0.268356 7.56662 -0.25094 8.20787 0.686394 8.49841L4.99544 9.8387L15.0045 3.57583C15.4755 3.26391 15.9038 3.4365 15.5515 3.74841L7.45514 11.0183Z"
                      fill="white" />
                  </svg>

                </i>
                <p>
                  Задать вопрос менеджеру в Telegram
                </p>
              </a>
              <a href="mailto:AM@remixmedia.ru" class="footer__menu-link footer__menu-link--social">
                <i>
                  <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M0.379272 1.11701V10.8908L5.26617 6.0039L0.379272 1.11701ZM1.35031 0.137695L7.5372 6.32459C8.31858 7.10597 9.68134 7.10597 10.4627 6.32459L16.6496 0.137695H1.35031Z"
                      fill="white" />
                    <path
                      d="M11.438 7.29995C10.7876 7.95098 9.9214 8.31029 9.00002 8.31029C8.07864 8.31029 7.21244 7.95098 6.56209 7.29995L6.2414 6.97926L1.35864 11.862H16.6414L11.7586 6.97926L11.438 7.29995ZM12.7338 6.00408L17.6207 10.891V1.11719L12.7338 6.00408Z"
                      fill="white" />
                  </svg>

                </i>
                <p>
                  AM@remixmedia.ru
                </p>
              </a>
              <p class="footer__menu-link">
                Москва, ул. Остоженка, 37/7с2, 4 этаж
                <br><br>
                Как к нам пройти: <br>
                Голубой особняк, вход через <br> Conversation Cafe, 4 этаж
              </p>
            </div>
          </div>
        </div>
        <div class="footer__bottom">
          <div class="footer__bottom-copy">
            <p>
              Идеи не могут повторяться дважды
              <br><br>
              ООО “Ремикс Медиа”
              <br><br>
              ©2023
            </p>
          </div>
          <div class="footer__bottom-conf">
            <a href="/">
              Политика конфиденциальности <br>
              и обработки персональных данных
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template> 

<script>
export default {
  name: 'Footers'
}
</script>